.container {
 max-width: 1320px;
 margin: 0 auto;   

    @include breakpoint("md-max") {
        padding: 0 40px;
    }

    @include breakpoint("sm-max") {
        padding: 0 20px;
    }
}