.btn {
  color: white;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-color: #828282;
  border-radius: 10px;
}

.btn-save,
.btn-save:hover,
.btn-save:active,
.btn-save:visited,
.btn-save:focus {
  background: darkblue !important;
  border-color: darkblue !important;
  color: white;
  width: 106px;
  cursor: pointer;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-radius: 10px;
}

.btn-cancel,
.btn-cancel:hover,
.btn-cancel:active,
.btn-cancel:visited,
.btn-cancel:focus {
  background: white !important;
  border-color: darkblue !important;
  color: darkblue;
  width: 106px;
  cursor: pointer;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-radius: 10px;
}

.ReactModal__Overlay--after-open {
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content--after-open {
  background: #1d2938 !important; 
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px 20px 0 0 !important;
  max-width: 648px !important;
  padding:  0 !important;
  border: none !important;
  width: 648px !important;
}

.cropper-heading-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #868998;
}

.cropper-back-arrow-alignment img {
  cursor: pointer;
}

.cropper-save-button button {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
  border: none;
  background: transparent;
}

.cropper {
  padding: 10px 0 0 0;
  width: 100% !important;
}

.cropper-modal-bootom-details {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cropper-option-box-contant {
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  background: rgba(26, 26, 26, 0.8);
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.cropper-heading h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212126;
}

.reactEasyCrop_Container {
  width: 100% !important;
}

.cropper-option-box-contant .cropper-option-dropdown-box-alignment {
  position: absolute;
  background: rgba(26, 26, 26, 0.8);
  border-radius: 8px;
  z-index: 3;
  max-width: 130px;
  width: 130px;
  height: auto;
  left: 0;
  bottom: 40px;
}

.cropper-option-dropdown-box-alignment .cropper-option-name-alignment {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-bottom: 0.5px solid #fff;
  cursor: pointer;
}

.cropper-option-name-alignment p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  margin: 0;
}

.cropper-option-img-alignment {
  width: 24px;
  height: 24px;
}
/* .slidecontainer {
  width: 100%;
} */

/* .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
} */
#range {
  width: auto;
  height: 3px;

  /*tira a barra do input range*/
  -webkit-appearance: none;

  background: #0a85ff;

  outline: none;
}

#range::-webkit-slider-thumb {
  -webkit-appearance: none;

  background: #333;

  width: 22px;
  height: 22px;

  border-radius: 50%;

  cursor: pointer;
}

@media only screen and (max-width: 524px) {
  .ReactModal__Content--after-open {
    max-width: 94% !important;
    width: 94% !important;
  }

  .cropper-back-arrow-alignment {
    width: 20px;
    height: 20px;
  }

  .cropper-back-arrow-alignment img {
    width: 100%;
    height: 100%;
  }

  .cropper-heading h6 {
    font-size: 16px;
  }
  .cropper-save-button button {
    font-size: 14px;
  }
}
