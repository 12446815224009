@mixin font16-mediun {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font16-bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font12 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font20 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font30 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

@mixin font22 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.2px;
  font-family: "Poppins", sans-serif;
}
