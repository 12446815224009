.upload-image {
  cursor: pointer;
  margin-top: 10px;
  color: darkblue;
  text-decoration: underline;
}

.default-upload-image {
  margin-top: 100%;
  margin-left: 15%;
  cursor: pointer;
}

.crop-button-alignment {
  position: absolute;
  left: 16px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.crop-button-alignment p {
  font-family: Poppins, sans-serif;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 0;
}

@media only screen and (max-width: 524px) {
  .crop-button-alignment {
    padding: 6px 10px;
  }
  .crop-button-alignment p {
    font-size: 12px;
  }

  .add-more-images {
    padding: 6px 10px;
    left: 80px;
  }
}
