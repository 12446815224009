body {
  margin: 0;
  padding: 0;
  background-color: var(--theme-full-page-background);
  font-family: 'Poppins', sans-serif;
}

* {
  box-sizing: border-box;
}

button,input,a {
  font-family: 'Poppins', sans-serif;
}

p:last-child {
  margin: 0;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}



/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: linear-gradient(180deg, #1FBA5C 0%, #147759 100%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

img[alt="RemoveIcon"] {
  cursor: pointer;
}