input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "✔";
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  display: flex;
  width: 18px;
  height: 18px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  font-size: 12px !important;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"]:checked + label:before {
  background: linear-gradient(95deg, #22c55e 0.52%, #115e59 104.33%);
  color: #fff;
}

input[type="checkbox"]:checked + label:after {
  display: block;
  position: absolute;
  top: 11px;
  left: 16px;
  width: 4px;
  height: 10px;
  border: 1px solid none !important;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
